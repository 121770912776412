import * as bootstrap from 'bootstrap';


// COPY TO CLIPBOARD
// ==============================================================================================
function copyText(element) {
  let textToCopy = element.innerText;

  let myTemporaryInputElement = document.createElement('input');

      myTemporaryInputElement.type = 'text';
      myTemporaryInputElement.value = textToCopy;

  document.body.appendChild(myTemporaryInputElement);
  myTemporaryInputElement.select();
  document.execCommand('Copy');
  document.body.removeChild(myTemporaryInputElement);
}

const copyToClipboard = document.querySelectorAll('.copyToClipboard');

copyToClipboard.forEach(function (button) {
  let copyTarget = button.previousElementSibling;
  let tooltip = new bootstrap.Tooltip(button);

  button.addEventListener('click', function () {
    copyText(copyTarget);
    tooltip.show();
    setTimeout(function () {
      tooltip.hide()
    }, 3000);
  });
});
// ==============================================================================================
// end of COPY TO CLIPBOARD


// SELECT
// ==============================================================================================
import NiceSelect from './lib/nice-select2';

const lpNiceSelect2 = document.querySelectorAll('.lpNiceSelect2');

lpNiceSelect2.forEach(function (item) {
  let optionsData = item.dataset.nsoptions;

  if (optionsData) {
    new NiceSelect(item, JSON.parse(optionsData));
  } else {
    new NiceSelect(item, {});
  }
});

// ==============================================================================================
// end of SELECT

// FIX NAV ON SCROLL
// ==============================================================================================
let scrollPosition = window.scrollY;
const header = document.getElementById('header');
const headerHeight = header.offsetHeight;
const headerTop = document.getElementById('headerTop');
const headerFixed = document.getElementById('headerFixed');
const headerFixedHeight = headerFixed.offsetHeight;
const headerFixedTop = headerFixed.getBoundingClientRect().top;

window.addEventListener('scroll', function() {
  scrollPosition = window.scrollY;

  const mql = window.matchMedia('(min-width: 992px)');

  if (scrollPosition >= headerFixedTop && mql.matches) {
    headerFixed.classList.add('position-fixed');
    headerTop.style.marginBottom = '' + headerFixedHeight + 'px';
  } else {
    headerFixed.classList.remove('position-fixed');
    headerTop.style.marginBottom = '0';
  }

  if (scrollPosition >= (headerHeight - headerFixedHeight) && mql.matches) {
    headerFixed.classList.add('showBottom');
  } else {
    headerFixed.classList.remove('showBottom');
  }
});
// ==============================================================================================
// end of FIX NAV ON SCROLL


// READ MORE
// ==============================================================================================
import ReadSmore from 'read-smore'
const readMores = document.querySelectorAll('.js-read-smore');

const rmOptions = {
  blockClassName: 'read-more',
  moreText: 'Подробнее',
  lessText: 'Свернуть'
}
ReadSmore(readMores, rmOptions).init();
// ==============================================================================================
// end fo READ MORE



// TOGGLE CLASS
// ==============================================================================================
const classToggler = document.querySelectorAll('.lpToggler');

classToggler.forEach(function (item) {
  item.addEventListener('click', function () {
    let classToToggle = item.dataset.classToggle;

    item.classList.toggle(classToToggle);
  })
});
// ==============================================================================================
// end of TOGGLE CLASS



// ADD TO CART (PRODUCT)
// ==============================================================================================
const addToCartContainer = document.getElementById('addToCartContainer');
const enableCartQty = document.getElementById('enableCartQty');
const cartQtyWrap = document.getElementById('cartQtyWrap');

if (addToCartContainer) {
  // Show qty container
  enableCartQty.addEventListener('click', function () {
    this.classList.add('d-none');

    cartQtyWrap.classList.remove('d-none');
  });
}
// ==============================================================================================
// end of ADD TO CART


// ADD TO CART QTY INPUT
// ==============================================================================================
const qtyInputGroup = document.querySelectorAll('.qtyInputGroup');

if (qtyInputGroup) {
  qtyInputGroup.forEach(function (group) {
    let qtyDecrease = group.querySelector('.qtyDecrease');
    let qtyEncrease = group.querySelector('.qtyEncrease');
    let qtyInput = group.querySelector('.qtyInput');
    let qtyInputMin = qtyInput.getAttribute('min');
    let qtyInputMax = qtyInput.getAttribute('max');

    qtyDecrease.addEventListener('click', function () {
      let qtyInputValue = +qtyInput.value;

      if (qtyInputValue > qtyInputMin) {
        qtyInput.value = qtyInputValue - 1;
      } else if (this.classList.contains('hideCardImageOverlay')) {
        this.closest('.cardImageOverlay').classList.add('d-none');
      } else if (this.classList.contains('disableCardQty')) {
        cartQtyWrap.classList.add('d-none');
        enableCartQty.classList.remove('d-none');
      }
    });

    qtyEncrease.addEventListener('click', function () {
      let qtyInputValue = +qtyInput.value;

      if (qtyInputValue < qtyInputMax)
      qtyInput.value = qtyInputValue + 1;
    });
  });
}
// ==============================================================================================
// end of ADD TO CART QTY INPUT

// PRODUCT CARD OVERLAY
// ==============================================================================================
const productCard = document.querySelectorAll('.productCard');

if (productCard) {
  productCard.forEach(function (card) {
    let cardImageOverlay = card.querySelector('.cardImageOverlay');
    let showCardImageOverlay = card.querySelector('.showCardImageOverlay');

    if (showCardImageOverlay) {
      showCardImageOverlay.addEventListener('click', function () {
        cardImageOverlay.classList.remove('d-none');
      })
    }
  });
}
// ==============================================================================================
// end of PRODUCT CARD OVERLAY


// TOOLTIPS
// ==============================================================================================
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
// ==============================================================================================
// end of TOOLTIPS



// WEB FONTS
// ==================================================================================================================
let WebFont = require('webfontloader');

WebFont.load({
  google: {
    families: ['Roboto:400,400i,500,500i,700,700i']
  }
});
// ==================================================================================================================
// end of WEB FONTS



// FORM VALIDATION
// ==================================================================================================================
(function () {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  let forms = document.querySelectorAll('.needs-validation');

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })
})();
// ==================================================================================================================
// end of FORM VALIDATION



// CAROUSELS
// ==================================================================================================================
import Splide from '@splidejs/splide';

const carousels = document.querySelectorAll( '.splide' );

carousels.forEach(function (item) {
  new Splide(item).mount();
});
// ==================================================================================================================
// end of CAROUSELS



// COUNTDOWN TIMERS
// ==================================================================================================================
const dateCountdownContainer = document.querySelectorAll('.dateCountdownContainer');

dateCountdownContainer.forEach(function (item) {
  let dayField = item.querySelector('.dayField');
  let hourField = item.querySelector('.hourField');
  let minuteField = item.querySelector('.minuteField');
  let secondField = item.querySelector('.secondField');

  let interval;

  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;

  let eventDay = new Date();

  eventDay = new Date(eventDay.getTime() + 7 * day);

  const countDownFn = () => {
    const today = new Date();
    const timeSpan = eventDay - today;

    if (timeSpan <= -today) {
      clearInterval(interval);
    } else if (timeSpan <= 0) {
      clearInterval(interval);
    } else {
      const days = Math.floor(timeSpan / day);
      const hours = Math.floor((timeSpan % day) / hour);
      const minutes = Math.floor((timeSpan % hour) / minute);
      const seconds = Math.floor((timeSpan % minute) / second);

      dayField.innerHTML = days;
      hourField.innerHTML = hours;
      minuteField.innerHTML = minutes;
      secondField.innerHTML = seconds;

    }
  };

  interval = setInterval(countDownFn, second);
});
// ==================================================================================================================
// end of COUNTDOWN TIMERS



// RADIO TOGGLE
// ==================================================================================================================
const showTarget = document.querySelectorAll('.showTarget');
const hideTarget = document.querySelectorAll('.hideTarget');

if (hideTarget) {
  hideTarget.forEach(function (item) {

    item.addEventListener('change', function () {
      let target = document.getElementById(item.dataset.target);

      if (item.checked) {
        target.classList.remove('show');
        target.classList.add('hide');
      }
    });
  });
}

if (showTarget) {
  showTarget.forEach(function (item) {

    item.addEventListener('change', function () {
      let target = item.dataset.target;

      if (item.checked) {
        document.getElementById(target).classList.add('show');
      }
    });
  });
}
// ==================================================================================================================
// end of RADIO TOGLE


// UPDATE CART BREAKDOWN
// ==================================================================================================================
function updateTextWithValue (el) {
  let value = el.dataset.value;
  let target = el.dataset.target;

  document.querySelectorAll('.' + target).forEach(function (target) {
    target.innerText = value;
  })
}

const updateTextWithValueTrigger = document.querySelectorAll('.updateTextWithValueTrigger');

if (updateTextWithValueTrigger) {
  updateTextWithValueTrigger.forEach(function (item) {
    if (item.checked) {
      updateTextWithValue(item);
    }

    item.addEventListener('change', function () {
      updateTextWithValue(item);
    });
  });
}
// ==================================================================================================================
// end of UPDATE CART BREAKDOWN



// MEGA MENU
// ==================================================================================================================
let getSiblings = function (elem) {

  let siblings = [];
  let sibling = elem.parentNode.firstChild;

  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling
  }

  return siblings;

};

if (window.innerWidth > 992) {
  const megaMenu = document.getElementById('megaMenu');
  if (megaMenu) {
    const megaMenuCategoriesLinks = megaMenu.querySelectorAll('.lp-sub-menu-toggle');
    const megaMenuCategoriesWrap = megaMenu.querySelector('.megaMenuCategoriesWrap');
    const lpMegaMenuCategoryListing = megaMenu.querySelectorAll('.lp-megaMenu-category-listing');

    lpMegaMenuCategoryListing.forEach(function (el) {
      el.style.left = megaMenuCategoriesWrap.offsetWidth + 12 + 'px';

      el.style.width = 'calc(100% - ' +  (megaMenuCategoriesWrap.offsetWidth + 24) + 'px)';
    });

    megaMenuCategoriesLinks.forEach(function (item) {
      item.addEventListener('mouseover', function (e) {
        item.classList.add('is-active');

        let itemSib = getSiblings(item.parentNode);

        itemSib.forEach(function (el) {
          let link = el.querySelector('a');

          link.classList.remove('is-active');
        })
      });
    });
  }
}
// ==================================================================================================================
// end of MEGA MENU



// OFFCANVAS NAV
// ==================================================================================================================
const offcanvasNav = document.getElementById('offcanvasNav');

if (offcanvasNav) {
  offcanvasNav.addEventListener('show.bs.offcanvas', function () {
    header.classList.add('is-open');
  });

  offcanvasNav.addEventListener('hide.bs.offcanvas', function () {
    header.classList.remove('is-open');
  });
}
// ==================================================================================================================
//end of OFFCANVAS NAV



// RANGE SLIDER
// ==================================================================================================================
import RangeSlider from 'js-rangeslider/src/js-rangeslider';

const rangeSlider = document.querySelectorAll('.rangeSlider');

if (rangeSlider) {
  rangeSlider.forEach(el => {
    new RangeSlider(el,{
      outputFormatter: output => `&euro;${output}`
    })
  });
}
// ==================================================================================================================
// end of RANGE SLIDER


// RADIO SWITCH
// ==================================================================================================================
const radioSwitch = document.querySelectorAll('[data-radio-switch] [type="radio"]');

if (radioSwitch) {

  radioSwitch.forEach(function (item) {
    item.addEventListener('click', function () {
      let target = document.getElementById(item.dataset.target);
      let targetSiblings = getSiblings(target);

      target.classList.remove('d-none');

      targetSiblings.forEach(function (sibling) {
        sibling.classList.add('d-none');
      });
    });
  })
}
// ==================================================================================================================
// end of RADIO SWITCH
